import React from 'react';

const Sun = () => {
    return (
        <div className='sun-content'>
            <div className='back-sun2'>
            </div>
            <div className='back-sun1'>
            </div>
            <div className='sun'></div>
        </div>
    );
}

export default Sun;
